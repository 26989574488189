import { Toaster as Sonner, type ToastT, toast } from 'sonner'

import { Icon } from './Icon'
import { Spinner } from './Spinner'

type ToasterProps = React.ComponentProps<typeof Sonner>

type Toast = {
  id?: ToastT['id']
  type: Extract<
    Required<ToastT>['type'],
    'success' | 'error' | 'warning' | 'info'
  >
  title: string
  description?: string
}

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      className="toaster group flex flex-col items-center"
      position="bottom-center"
      icons={{
        success: <Icon name="confetti" size="md" />,
        loading: <Spinner size="sm" />,
        error: <Icon name="warning" size="lg" />,
      }}
      toastOptions={{
        unstyled: true,
        duration: 5000,
        classNames: {
          success:
            'bg-success-300/80 text-success-800 dark:bg-success-400/30 dark:text-success-200',
          error: 'bg-destructive-200/80 text-destructive-800',
          loading: 'bg-primary text-white',
          warning:
            'bg-warning-300/80 text-warning-700 dark:bg-warning-200/70 dark:text-warning-800',
          info: 'bg-accent-300/80 text-accent-800 dark:bg-accent-200/70',
          title: '!font-semibold leading-tight',
          content: 'flex flex-col !gap-0',
          toast:
            'shadow-lg text-sm px-4 py-2 flex items-center gap-2 rounded-md min-w-72 font-sans backdrop-blur-md',
          description: 'group-[.toast]:text-muted-foreground text-xs',
          actionButton:
            'group-[.toast]:bg-primary group-[.toast]:text-primary-foreground',
          cancelButton:
            'group-[.toast]:bg-muted group-[.toast]:text-muted-foreground',
        },
      }}
      {...props}
    />
  )
}

export { type Toast, toast, Toaster }
